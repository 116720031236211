<template>
  <section class="start-today">
    <div class="container">
      <div class="content">
        <div class="box text wow slideInLeft">
          <h2>Začni dnes</h2>
          <p>
            Bez ohľadu na vašu aktuálnu úroveň kondície, naši skúsení tréneri
            vám pomôžu vytvoriť plán, ktorý je prispôsobený vašim potrebám.
            Začnite teraz a zmeňte svoj život k lepšiemu!
          </p>
        </div>
        <div class="box img wow slideInRight">
          <img src="@/assets/images/diamond_horne_poschodie3.jpg" alt="Začni dnes" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "StartTodaySection",
}
</script>

<style scoped>
/* Add your Start Today section styles */
.start-today {
  background: #E8B923;
  color: #000;
  padding: 80px 20px;
}
.start-today .content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.start-today .text {
  flex: 1 1 50%;
  padding: 20px;
}
.start-today .text h2 {
  font-size: 32px;
  margin-bottom: 15px;
}
.start-today .img {
  flex: 1 1 50%;
  padding: 20px;
}
.start-today .img img {
  width: 100%;
  border-radius: 8px;
}
</style>
