<template>
  <section class="price-package" id="price">
    <div class="container">
      <h2>Cenník</h2>
      <img src="@/assets/images/c1.jpg" alt="Cenník" />
    </div>
  </section>
</template>

<script>
export default {
  name: "PriceSection",
}
</script>

<style scoped>
/* Add your Price section styles */
.price-package {
  background-color: #000;
  border-top: 2px solid #E8B923;
  padding: 80px 20px;
  text-align: center;
}
.price-package h2 {
  font-size: 32px;
  margin-bottom: 15px;
  color: #E8B923;
}
.price-package img {
  max-width: 100%;
  height: auto;
}
</style>
