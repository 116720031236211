<template>
  <section class="contact" id="contact">
    <div class="container">
      <div class="content">
        <!-- Form Column -->
        <div class="box form wow slideInLeft">
          <form @submit.prevent="handleSubmit">
            <input type="text" placeholder="Enter Name" v-model="contactForm.name" required />
            <input type="email" placeholder="Enter Email" v-model="contactForm.email" required />
            <input type="text" placeholder="Enter Mobile" v-model="contactForm.mobile" />
            <textarea placeholder="Enter Message" v-model="contactForm.message" required></textarea>
            <button type="submit">
              <span class="fa fa-envelope"></span> Send Message
            </button>
          </form>
        </div>
        <!-- Info Column -->
        <div class="box text wow slideInRight">
          <h2>Skontaktujte nás</h2>
          <p class="title-p">
            Ak máte akékoľvek otázky alebo záujem o naše služby, neváhajte nás kontaktovať.
            Naša prívetivá a odborná obsluha je pripravená vám pomôcť a poskytnúť ďalšie informácie.
          </p>
          <div class="info">
            <ul>
              <li><span class="fa fa-map-marker"></span> Nitra 949 01, Štúrova 1441/46</li>
              <li>
                <span class="fa fa-phone"></span>
                <a href="tel:0376503689">037/650 36 89</a>
              </li>
              <li>
                <span class="fa fa-envelope"></span>
                <a href="mailto:diamond46nitra@gmail.com">diamond46nitra@gmail.com</a>
              </li>
            </ul>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10614.54220047989!2d18.0759046!3d48.3098604!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476b3f1c79f795c7:0x272cfd4be5b64c24!2sDiamond%20Gym!5e0!3m2!1sen!2ssk!4v1687868904798!5m2!1sen!2ssk"
            width="300"
            height="212"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade">
          </iframe>
          <div class="social">
            <a href="https://www.facebook.com/diamond.gym.nr"><span class="fa fa-facebook"></span></a>
            <a href="https://www.instagram.com/diamondgym/"><span class="fa fa-instagram"></span></a>
            <a href="https://www.youtube.com/@diamondgym46"><span class="fa fa-youtube-play"></span></a>
          </div>
          <div class="copy">
            2025 &copy; Diamond Gym All rights reserved
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { reactive } from 'vue'
export default {
  name: "ContactSection",
  setup(_, { emit }) {
    const contactForm = reactive({
      name: "",
      email: "",
      mobile: "",
      message: "",
    })

    const handleSubmit = () => {
      emit('submit-form', { ...contactForm })
      alert("Message sent!")
      contactForm.name = ""
      contactForm.email = ""
      contactForm.mobile = ""
      contactForm.message = ""
    }

    return {
      contactForm,
      handleSubmit,
    }
  }
}
</script>

<style scoped>
/* Add your Contact section styles */
.contact {
  background-color: #111;
  padding: 80px 20px;
  color: #fff;
}
.contact .container {
  max-width: 1200px;
  margin: 0 auto;
}
.contact .content {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}
.box.form {
  flex: 1 1 45%;
  background: #1a1a1a;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(232, 185, 35, 0.2);
}
.box.form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.box.form input,
.box.form textarea {
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  background: #333;
  color: #fff;
}
.box.form textarea {
  resize: vertical;
  min-height: 100px;
}
.box.form button {
  padding: 12px;
  background-color: #e8b923;
  border: none;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.box.form button:hover {
  background-color: #d0a31e;
}
.box.text {
  flex: 1 1 45%;
  color: #ccc;
}
.box.text h2 {
  font-size: 32px;
  margin-bottom: 15px;
  color: #e8b923;
}
.box.text .title-p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #ccc;
}
.info ul {
  list-style: none;
  margin-bottom: 20px;
  padding: 0;
}
.info ul li {
  font-size: 15px;
  margin-bottom: 10px;
  color: #ccc;
}
.info ul li span {
  margin-right: 10px;
  color: #e8b923;
}
.info ul li a {
  color: #e8b923;
  text-decoration: none;
}
iframe {
  border-radius: 8px;
  margin-bottom: 20px;
}
.social a {
  font-size: 24px;
  color: #e8b923;
  margin-right: 10px;
  transition: color 0.3s ease;
}
.social a:hover {
  color: #fff;
}
.copy {
  font-size: 14px;
  color: #aaa;
}
@media (max-width: 768px) {
  .contact .content {
    flex-direction: column;
  }
  .box.form,
  .box.text {
    flex: 1 1 100%;
  }
}
</style>
