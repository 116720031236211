<template>
  <section class="classes" id="classes">
    <div class="container">
      <!-- Left Column: Image -->
      <div class="box img wow slideInLeft hover-card">
        <img src="@/assets/images/diamond_gym_horne_poschodie.jpg" alt="Classes" />
      </div>

      <!-- Right Column: Text & Items -->
      <div class="box text wow slideInRight hover-effect">
        <h2>Možnosti u nás</h2>
        <p>
          Začnite cvičiť dnes a dosiahnite svoje ciele s našou pomocou! Nech už chcete zlepšiť kondíciu, schudnúť alebo posilniť sa, sme tu pre vás na každom kroku.
        </p>
        <div class="class-items">
          <div class="item wow bounceInUp">
            <div class="item-text">
              <h4>Tréning</h4>
              <p>Dosiahnite svoje ciele vďaka nášmu skvelému tréningu a profesionálnemu vedeniu.</p>
            </div>
          </div>
          <div class="item wow bounceInUp">
            <div class="item-text">
              <h4>Masáže</h4>
              <p>Naše masáže vám poskytnú úžasný relaxačný zážitok a pomôžu vám obnoviť sa po náročnom tréningu. <strong>MOMENTÁLNE NEDOSTUPNÉ</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
/* Overall Styling */
.classes {
  background-color: #111;
  padding: 60px 20px;
  color: #fff;
}

/* Flexbox Layout */
.container {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  margin: auto;
  gap: 20px;
}

.box {
  flex: 1;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.img img {
  width: 100%;
  max-width: 500px;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.text {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(232, 185, 35, 0.2);
}

/* Hover Effects */
.hover-effect:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.hover-card {
  background-color: #1a1a1a;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(232, 185, 35, 0.3);
  padding: 20px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.hover-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 14px rgba(232, 185, 35, 0.5);
}

/* Class Items */
.class-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item {
  display: flex;
  gap: 15px;
  background: #2a2a2a;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(232, 185, 35, 0.1);
  align-items: center;
}

.item-img {
  flex: 0 0 80px; /* Adjusted width of small image */
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-img img {
  width: 80px; /* Reduced size for better fit */
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}

/* Responsive Design */
@media (max-width: 992px) {
  .container {
    flex-direction: column;
  }
  .box {
    min-width: 100%;
  }
  .item-img img {
    width: 60px; /* Smaller image for mobile */
    height: 60px;
  }
}
</style>
