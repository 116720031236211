<template>
  <div id="app">
    <HeaderComponent 
      :navOpen="navOpen" 
      @toggle-nav="toggleNav" 
      @close-nav="closeNav" 
    />
    <HomeSection />
    <AboutSection />
    <ServiceSection />
    <ClassesSection />
    <StartTodaySection />
    <ScheduleSection />
    <PriceSection />
    <TrainersSection :trainers="trainers" />
    <ContactSection @submit-form="submitForm" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { WOW } from 'wowjs'
import HeaderComponent from './components/HeaderComponent.vue'
import HomeSection from './components/HomeSection.vue'
import AboutSection from './components/AboutSection.vue'
import ServiceSection from './components/ServiceSection.vue'
import ClassesSection from './components/ClassesSection.vue'
import StartTodaySection from './components/StartTodaySection.vue'
import ScheduleSection from './components/ScheduleSection.vue'
import PriceSection from './components/PriceSection.vue'
import TrainersSection from './components/TrainersSection.vue'
import ContactSection from './components/ContactSection.vue'

export default {
  name: "App",
  components: {
    HeaderComponent,
    HomeSection,
    AboutSection,
    ServiceSection,
    ClassesSection,
    StartTodaySection,
    ScheduleSection,
    PriceSection,
    TrainersSection,
    ContactSection,
  },
  setup() {
    const navOpen = ref(false)
    const toggleNav = () => {
      navOpen.value = !navOpen.value
    }
    const closeNav = () => {
      navOpen.value = false
    }

    const trainers = ref([
      { name: 'Arben', image: require('@/assets/images/5.png') },
      { name: 'Klaudia', image: require('@/assets/images/2.png') },
      { name: 'Adrian', image: require('@/assets/images/1.png') },
      { name: 'Stefi', image: require('@/assets/images/3.png') },
      { name: 'Michal', image: require('@/assets/images/4.png') },
      { name: 'Eliška', image: require('@/assets/images/6.png') }
    ])

    onMounted(() => {
      new WOW().init()
    })

    const submitForm = (contactFormData) => {
      console.log("Contact form submitted:", contactFormData)
      alert("Message sent!")
      // Additional actions like API calls can be added here.
    }

    return {
      navOpen,
      toggleNav,
      closeNav,
      trainers,
      submitForm,
    }
  }
}
</script>

<style>
/* Global styles or imports */
</style>
