<template>
  <section class="about" id="about">
    <div class="container content">
      <div class="box wow bounceInUp">
        <div class="inner">
          <div class="img">
            <img src="@/assets/images/diamond_gym_vahy.jpg" alt="Diamond Gym Nitra váhová zóna" />
          </div>
          <div class="text">
            <h4>Váhová zóna</h4>
            <p>
              Váhová zóna v posilovni je oblasť s rôznymi cvičebnými strojmi a
              vybavením na posilňovanie svalovej sily a vytrvalosti. Obsahuje
              voľné činky, posilňovacie lavice a multifunkčné stroje pre
              tréning s činkami.
            </p>
          </div>
        </div>
      </div>
      <div class="box wow bounceInUp" data-wow-delay="0.2s">
        <div class="inner">
          <div class="img">
           <!----> <img src="@/assets/images/diamond_gym_kardio.jpg" alt="Kardio zóna" />
          </div>
          <div class="text">
            <h4>Kardio zóna</h4>
            <p>
              Kardio zóna v posilovni je oblasť s rôznymi cvičebnými strojmi,
              ako sú bežecké pásy, eliptické tréningové stroje a bicykle.
              Je určená na kardiovaskulárny tréning a zlepšenie vytrvalosti.
            </p>
          </div>
        </div>
      </div>
      <div class="box wow bounceInUp" data-wow-delay="0.4s">
        <div class="inner">
          <div class="img">
            <img src="@/assets/images/diamond_fight_zona.jpg" alt="Fight zóna" />
          </div>
          <div class="text">
            <h4>Fight zóna</h4>
            <p>
              Fight zóna je špeciálna oblasť v posilovni vyhradená pre bojové
              športy a tréningy sebaobrany, s vhodným vybavením pre box, MMA a
              ďalšie bojové umenia.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutSection",
}
</script>

<style scoped>
/* Add your About section styles */
.about .content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.about .box {
  flex: 1 1 calc(33.33% - 20px);
  background: #1a1a1a;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 8px rgba(232, 185, 35, 0.2);
}
.about .box:hover {
  transform: translateY(-5px);
}
.about .box .inner {
  display: flex;
  flex-direction: column;
}
.about .box .img img {
  width: 100%;
  display: block;
}
.about .box .text {
  padding: 20px;
}
.about .box .text h4 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #E8B923;
}
.about .box .text p {
  font-size: 14px;
  line-height: 1.6;
  color: #ccc;
}
@media (max-width: 768px) {
  .about .content {
    flex-direction: column;
    align-items: center;
  }

  .about .box {
    width: 90%; /* Make cards take more space on mobile */
    flex: none; /* Override flex behavior */
  }

  .about .box .text p {
    display: none; /* Hides the paragraph on mobile screens */
  }

  .about .box .text h4 {
    font-size: 24px; /* Slightly larger title for visibility */
    text-align: center;
  }
}

</style>
