<template>
  <section class="service" id="service">
    <div class="container content">
      <div class="text box wow slideInLeft">
        <h2>Služby</h2>
        <p>
          Vitajte v našej rozmanitej ponuke služieb, kde sme sa snažili splniť
          vaše potreby a poskytnúť vám vynikajúci zážitok. Sme tu, aby sme vás
          podporili vo vašej ceste k lepšiemu zdraviu, kondícii a pohodliu.
        </p>
        <p>
          Od tréningových programov po wellness služby, naše široké spektrum
          služieb je navrhnuté tak, aby vám pomohlo dosiahnuť vaše ciele
          a cítiť sa najlepšie vo vašom tele.
        </p>
      </div>
      <div class="accordian box wow slideInRight">
        <div
          class="accordian-container"
          :class="{ active: activeAccordion === 0 }"
          @click="toggleAccordion(0)"
        >
          <div class="head">
            <h4>Silový tréning</h4>
            <span
              class="fa"
              :class="{
                'fa-angle-down': activeAccordion !== 0,
                'fa-angle-up': activeAccordion === 0
              }"
            ></span>
          </div>
          <div class="body" v-show="activeAccordion === 0">
            <p>
              Silový tréning je účinná forma cvičenia, ktorá zvyšuje svalovú
              silu a výkon. Pomáha budovať svalovú hmotu, zlepšuje metabolizmus
              a prispieva k lepšej postave.
            </p>
          </div>
        </div>
        <div
          class="accordian-container"
          :class="{ active: activeAccordion === 1 }"
          @click="toggleAccordion(1)"
        >
          <div class="head">
            <h4>Kondičný tréning</h4>
            <span
              class="fa"
              :class="{
                'fa-angle-down': activeAccordion !== 1,
                'fa-angle-up': activeAccordion === 1
              }"
            ></span>
          </div>
          <div class="body" v-show="activeAccordion === 1">
            <p>
              Kondičný tréning je základným pilierom udržiavania celkového
              zdravia a kondície, zlepšuje srdcovo-cievny výkon, vytrvalosť,
              flexibilitu a silu.
            </p>
          </div>
        </div>
        <div
          class="accordian-container"
          :class="{ active: activeAccordion === 2 }"
          @click="toggleAccordion(2)"
        >
          <div class="head">
            <h4>Masáže</h4>
            <span
              class="fa"
              :class="{
                'fa-angle-down': activeAccordion !== 2,
                'fa-angle-up': activeAccordion === 2
              }"
            ></span>
          </div>
          <div class="body" v-show="activeAccordion === 2">
            <p>
              Masáže sú terapeutické procedúry na uvoľnenie svalového napätia,
              zmiernenie bolesti a zlepšenie cirkulácie krvi. Rôzne techniky
              môžu byť prispôsobené vašim potrebám.
            </p>
          </div>
        </div>
        <div
          class="accordian-container"
          :class="{ active: activeAccordion === 3 }"
          @click="toggleAccordion(3)"
        >
          <div class="head">
            <h4>Bar</h4>
            <span
              class="fa"
              :class="{
                'fa-angle-down': activeAccordion !== 3,
                'fa-angle-up': activeAccordion === 3
              }"
            ></span>
          </div>
          <div class="body" v-show="activeAccordion === 3">
            <p>
              V našom bare so supplementmi nájdete výber kvalitných výživových
              doplnkov, ktoré podporujú svalový rast a regeneráciu.
            </p>
          </div>
        </div>
        <div
          class="accordian-container"
          :class="{ active: activeAccordion === 4 }"
          @click="toggleAccordion(4)"
        >
          <div class="head">
            <h4>Recenzie</h4>
            <span
              class="fa"
              :class="{
                'fa-angle-down': activeAccordion !== 4,
                'fa-angle-up': activeAccordion === 4
              }"
            ></span>
          </div>
          <div class="body" v-show="activeAccordion === 4">
            <p>
              Recenzie sú hodnotenia a skúsenosti ľudí s produktmi a službami,
              pomáhajú informovať a usmerňovať zákazníkov. <br />
              <button @click.stop="openReviewLink">Link na recenzie</button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
export default {
  name: "ServiceSection",
  setup() {
    const activeAccordion = ref(null)
    const toggleAccordion = (index) => {
      activeAccordion.value = activeAccordion.value === index ? null : index
    }
    const openReviewLink = () => {
      window.open(
        'https://www.google.com/maps/place/Diamond+Gym/@48.3092603,18.0748924,17z/',
        '_blank'
      )
    }
    return {
      activeAccordion,
      toggleAccordion,
      openReviewLink,
    }
  }
}
</script>

<style scoped>
/* Add your Service section styles */
.service {
  background-color: #000;
  border-top: 2px solid #E8B923;
  border-bottom: 2px solid #E8B923;
  padding: 80px 20px;
}
.service .content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.service .text.box {
  flex: 1 1 45%;
  padding: 20px;
  color: #ccc;
}
.accordian.box {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.accordian-container {
  background: #1a1a1a;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(232, 185, 35, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.accordian-container:hover,
.accordian-container.active {
  background-color: #2a2a2a;
}
.accordian-container .head {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordian-container .head h4 {
  font-size: 16px;
  color: #E8B923;
}
.accordian-container .head .fa {
  font-size: 16px;
  color: #E8B923;
}
.accordian-container .body {
  padding: 15px;
  border-top: 1px solid #333;
  background-color: #1a1a1a;
  color: #ccc;
}
@media (max-width: 768px) {
  .service .content {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
  }

  .service .text.box {
    width: 100%; /* Full width for text section */
    text-align: center; /* Center align text */
  }

  .accordian.box {
    width: 100%; /* Full width for accordion */
  }
}




</style>
