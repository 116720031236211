<template>
  <section class="home" id="home">
    <div class="container">
      <h1 class="wow slideInLeft" data-wow-delay="0.8s">
        No<span> pain</span> no gain
      </h1>
      <h1 class="wow slideInRight" data-wow-delay="1s">
        We are <span>Diamond Gym</span>
      </h1>
    </div>
    <a href="#about" class="go-down wow fadeInUp" data-wow-delay="1.5s">
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </a>
  </section>
</template>

<script>
export default {
  name: "HomeSection",
}
</script>

<style scoped>
/* Add your home section styles */
.home {
  background: url("@/assets/images/h.png") center/cover no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.home h1 {
  font-size: 48px;
  text-align: center;
  margin: 10px 0;
}
.home h1 span {
  color: #E8B923;
}
.go-down {
  margin-top: 30px;
  width: 40px;
  height: 40px;
  border: 2px solid #E8B923;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #E8B923;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.go-down:hover {
  background-color: #E8B923;
  color: #000;
}
</style>
