<template>
  <section class="schedule" id="schedule">
    <div class="container">
      <div class="content">
        <!-- Left Column: Title & Description -->
        <div class="box text wow slideInLeft">
          <h2>Otváracie Hodiny</h2>
          <p>
            Vitajte v našom fitnes centre! Radi by sme vám poskytli informácie o
            našich otváracích hodinách, aby ste vedeli, kedy sme k dispozícii.
            Naše otváracie hodiny sú prispôsobené vašim potrebám, aby ste mohli
            trénovať vo vhodnom čase. Tešíme sa na vašu návštevu!
          </p>
          <p class="note">
            <b>Otváracie hodiny počas sviatkov môžu byť iné ako uvedené.</b>
          </p>
        </div>
        <!-- Right Column: Image & Table -->
        <div class="box timing wow slideInRight">
          <div class="hours-container">
            <!-- Image Column -->
            <div class="img-wrapper">
              <img src="@/assets/images/diamond_pohlad_na_recepciu.jpg" alt="schedule" />
            </div>
            <!-- Table Column -->
            <div class="table-wrapper">
              <table class="schedule-table">
                <tbody>
                  <tr>
                    <td class="day">Pondelok</td>
                    <td><strong>6:00</strong></td>
                    <td><strong>22:00</strong></td>
                  </tr>
                  <tr>
                    <td class="day">Utorok</td>
                    <td><strong>6:00</strong></td>
                    <td><strong>22:00</strong></td>
                  </tr>
                  <tr>
                    <td class="day">Streda</td>
                    <td><strong>6:00</strong></td>
                    <td><strong>22:00</strong></td>
                  </tr>
                  <tr>
                    <td class="day">Štvrtok</td>
                    <td><strong>6:00</strong></td>
                    <td><strong>22:00</strong></td>
                  </tr>
                  <tr>
                    <td class="day">Piatok</td>
                    <td><strong>6:00</strong></td>
                    <td><strong>22:00</strong></td>
                  </tr>
                  <tr>
                    <td class="day">Sobota</td>
                    <td><strong>8:00</strong></td>
                    <td><strong>21:00</strong></td>
                  </tr>
                  <tr>
                    <td class="day">Nedeľa</td>
                    <td><strong>8:00</strong></td>
                    <td><strong>21:00</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ScheduleSection",
}
</script>

<style scoped>
/* Add your Schedule section styles */
.schedule {
  background: #111;
  padding: 80px 20px;
  color: #fff;
}
.schedule .content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.schedule .box.text {
  flex: 1 1 40%;
  padding: 20px;
}
.schedule .box.timing {
  flex: 1 1 60%;
  display: flex;
  flex-direction: column;
}
.hours-container {
  display: flex;
  height: 400px;
  margin-bottom: 15px;
}
.img-wrapper {
  flex: 1;
  display: flex;
}
.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.schedule-table {
  border-collapse: collapse;
  margin: 0 auto;
  width: 80%;
  max-width: 300px;
}
.schedule-table td {
  border: 1px solid #333;
  padding: 12px;
  text-align: center;
  font-size: 15px;
  color: #ccc;
}
.schedule-table .day {
  font-weight: 600;
  color: #fff;
  background: #222;
}
.schedule-table tr:hover {
  background-color: #1a1a1a;
}
.note {
  font-size: 14px;
  color: #aaa;
  margin-top: 15px;
}
@media (max-width: 768px) {
  .schedule .content {
    flex-direction: column;
  }
  .hours-container {
    flex-direction: column;
    height: auto;
  }
  .img-wrapper img {
    height: auto;
    margin-bottom: 15px;
  }
  .schedule-table {
    width: 100%;
    max-width: 100%;
  }
}
</style>
