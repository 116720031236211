<template>
  <header class="header">
    <div class="container">
      <div class="logo">
        <img
          src="@/assets/images/DG2.jpg"
          style="width: 60px; height: 45px;"
          alt="logo"
        />
        <a href="">Diamond <span>Gym</span></a>
      </div>
      <a href="javascript:void(0)" class="ham-burger" @click="toggleNav">
        <span></span>
        <span></span>
      </a>
      <nav class="nav" :class="{ open: navOpen }">
        <ul>
          <li><a href="#home" @click="closeNav">Domov</a></li>
          <li><a href="#about" @click="closeNav">O nás</a></li>
          <li><a href="#service" @click="closeNav">Služby</a></li>
          <li><a href="#classes" @click="closeNav">Možnosti</a></li>
          <li><a href="#schedule" @click="closeNav">Otváracie Hodiny</a></li>
          <li><a href="#price" @click="closeNav">Cenník</a></li>
          <li><a href="#contact" @click="closeNav">Kontakt</a></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      navOpen: false,
    };
  },
  methods: {
    toggleNav() {
      this.navOpen = !this.navOpen;
    },
    closeNav() {
      this.navOpen = false;
    }
  }
};
</script>

<style scoped>
/* Header Styles */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #000;
  border-bottom: 2px solid #E8B923;
  z-index: 100;
  padding: 15px 0;
}
.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo a {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
}
.logo a span {
  color: #E8B923;
}
.ham-burger {
  display: block;
  cursor: pointer;
  position: relative;
  z-index: 110;
}
.ham-burger span {
  display: block;
  width: 30px;
  height: 3px;
  background-color: #fff;
  margin-bottom: 5px;
  transition: transform 0.5s ease;
}
.nav {
  display: flex;
  align-items: center;
}
.nav ul {
  display: flex;
  gap: 20px;
  list-style: none;
}
.nav ul li a {
  font-size: 16px;
  font-weight: 400;
  padding: 8px 12px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 4px;
}
.nav ul li a:hover {
  background-color: #E8B923;
  color: #000;
}

/* Mobile Header Nav */
@media (max-width: 768px) {
  .ham-burger {
    display: block;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 110;
  }
  .nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 260px;
    height: 100%;
    background-color: #000;
    flex-direction: column;
    gap: 30px;
    padding-top: 100px;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 109;
  }
  .nav.open {
    transform: translateX(0);
  }
  .nav ul {
    flex-direction: column;
  }
  .nav ul li a {
    font-size: 20px;
    color: #fff;
  }
}
@media (min-width: 769px) {
  .ham-burger {
    display: none; /* Hide burger menu on larger screens */
  }
}

</style>
