<template>
  <section class="treneri" id="treneri">
    <div class="container">
      <h2>Tréneri</h2>
      <div class="trainers-grid">
        <div
          class="trainer-card wow fadeInUp"
          v-for="(trainer, index) in trainers"
          :key="index"
          :style="{ animationDelay: (index * 0.1) + 's' }"
        >
          <img :src="trainer.image" :alt="trainer.name" />
          <h4>{{ trainer.name }}</h4>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TrainersSection",
  props: {
    trainers: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
/* Add your Trainers section styles */
.treneri {
  background: #111;
  color: #fff;
  padding: 80px 20px;
  text-align: center;
}
.treneri h2 {
  font-size: 32px;
  color: #E8B923;
  margin-bottom: 30px;
}
.trainers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}
.trainer-card {
  background: #1a1a1a;
  border-radius: 8px;
  padding: 30px;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 10px rgba(232, 185, 35, 0.3);
}
.trainer-card:hover {
  transform: translateY(-5px);
}
.trainer-card img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
}
.trainer-card h4 {
  font-size: 24px;
  color: #E8B923;
}
</style>
